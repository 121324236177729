import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from '@reach/router';
interface IRoute {
  dataFromParent?: any;
}

type Props = { component: FunctionComponent } & RouteComponentProps & IRoute;

const Route: FunctionComponent<Props> = ({ component: Component, ...rest }) => (
  <Component {...rest} />
);

export default Route;
