import React from 'react';
import {
  Typography,
  ThemeProvider,
  CssBaseline,
  Box,
  makeStyles,
} from '@material-ui/core';
import theme from '../Theme/theme';
import { TransitionLink } from '../components/Common/TransitionLink/TransitionLink';
import Bg from '../images/404/element_404.svg';
import ImgChat from '../images/404/chat.svg';

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(14, 12, 56)',
    width: '100%',
    margin: 'auto',
    height: '100vh',
    textAlign: 'center',
    display: 'flex',
    padding: theme.spacing(3, 0),
    '& img': {
      width: '100%',
      maxWidth: 270,
      '@media (max-height: 540px)': {
        maxWidth: 200,
      },
      '@media (max-height: 468px)': {
        maxWidth: 175,
      },
      '@media (max-height: 428px)': {
        maxWidth: 160,
      },
      '@media (max-height: 400px)': {
        maxWidth: 145,
      },
    },
    '& h1': {
      marginBottom: theme.spacing(0),
      fontSize: '12.5rem',
      fontWeight: 600,
      '@media(max-width: 992px)': {
        fontSize: '3rem',
      },
    },
    '& h4': {
      marginBottom: theme.spacing(3),
      fontSize: '1rem',
      fontWeight: 100,
    },
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  Bg404: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${Bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '0',
    backgroundPositionY: '0',
    zIndex: 1,
  },
  imgContainer: {
    width: '100%',
    maxWidth: 800,
    margin: 'auto',
    zIndex: 2,
    position: 'relative',
    '@media(max-width: 992px)': {
      width: '100%',
    },
    '& .MuiTypography-root': {
      color: '#ffffff',
    },
  },
  buttonRetour: {
    color: '#E0363A!important',
    border: '1px solid #E0363A',
    borderRadius: 4,
    fontSize: '0.875rem',
    maxWidth: 301,
    margin: '50px auto 0',
    padding: '14px 36px',
    '&:hover': {
      cursor: 'pointer',
    },
    '@media (max-height: 468px)': {
      margin: '38px auto 0',
      padding: '12px 24px',
      maxWidth: 275,
    },
    '@media (max-height: 428px)': {
      margin: '24px auto 0',
    },
  },
}));

function Page404() {
  /* const image = useStaticQuery(graphql`
    query {
      file(name: { eq: "404-2x" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);*/
  const classes = styles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className={classes.root}>
        <Box className={classes.Bg404} />
        <Box className={classes.imgContainer}>
          <Typography variant="h1" color="textSecondary">
            Oops!
          </Typography>
          <Typography variant="h2" color="textSecondary">
            404 - Page introuvable
          </Typography>
          <Typography variant="h4" color="textSecondary">
            la page que vous recherchez n'existe pas.
          </Typography>
          {/*<Img fluid={image.file.childImageSharp.fluid} alt="404" />*/}
          <img src={ImgChat} alt="Chat" />
          <TransitionLink
            to="/"
            settings={{
              top: 'exit',
              direction: 'up',
              duration: 0.8,
              bg: '#FFF',
            }}
          >
            <Typography className={classes.buttonRetour} color="textSecondary">
              Retour à la page d'accueil
            </Typography>
          </TransitionLink>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Page404;
