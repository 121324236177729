import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import RecrutementPage from './recrutement';
import { RecrutementProvider } from '../providers/recrutement';
import Route from '../components/Common/Route/Route';
import Page404 from './404';

const App = (data: any) => {
  return (
    <RecrutementProvider>
      <Router>
        <Route component={RecrutementPage} path="/recrutement/" />
        <Route
          component={() => <RecrutementPage data={data} />}
          path="/recrutement/:slug/:id"
        />
        <Route
          component={() => <RecrutementPage data={data} />}
          path="/recrutement/:id"
        />
        <Route component={Page404} default={true} path="/" />
      </Router>
    </RecrutementProvider>
  );
};
export default App;
